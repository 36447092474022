<template>
  <div class="pb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-10">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Approved Users</h2>
        <router-link :to="{ name: 'users-new' }" class="text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>
    </div>

    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :columns="users.columns"
        :query="users.query"
        :url="users.url"
        :on-click="click"
        sort="created_at"
        :filters="users.filters"
        order="desc"
        ref="table"
      >
        <template #header>
          <nav class="border-b-2 border-blue-200 px-8 mb-5">
            <ul class="flex flex-wrap items-center w-full">
              <li
                class="-mb-2px -mt-5 pr-20 relative"
                v-for="(filter, index) in users.filters"
                :key="index"
              >
                <a
                  href="#!"
                  class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
                  :class="{
                    'border-blue-500 text-blue-500 hover:text-blue-500':
                      currentFilter === filter
                  }"
                  @click.prevent="toggleFilter(filter)"
                >
                  <span class="mr-2">{{ filter.title }}</span>
                </a>
                <span
                  class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2"
                />
              </li>
            </ul>
          </nav>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      users: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/status/approved`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'User ID'
          },
          {
            name: 'name',
            th: 'Name',
            render: user => user.name + ' ' + user.last_name
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name
          },
          {
            name: 'email',
            th: 'Email Address'
          },
          {
            name: 'progress',
            th: 'Progress',
            render: user => {
              return this.progressIcons(user);
            },
            sortable: false
          },
          {
            name: 'status',
            th: 'Status',
            render: (user, status) => {
              return this.statusBadge(status, user);
            },
            sortable: false
          },
          {
            name: 'admins',
            th: 'Assigned To',
            render: user => {
              return this.assignedAdmin(user);
            },
            sortable: false
          },
          {
            name: 'approved_by',
            th: 'Approved By',
            render: ({ credit_limit }) =>
              credit_limit
                ? credit_limit?.authorizer_id
                  ? `<div class="badge badge-blue-soft-outline">
                      ${credit_limit?.authorizer?.name} ${credit_limit?.autorizer?.last_name}
                    </div>`
                  : '<div class="badge badge-purple-soft-outline">Loan Bot</div>'
                : 'N/A'
          },
          {
            name: 'approved_at',
            th: 'Date Approved',
            render: ({ credit_application }) =>
              credit_application?.attended_at
                ? this.$moment(credit_application?.attended_at).format(
                    'MMM Do YYYY'
                  )
                : 'N/A'
          },
          {
            name: 'created_at',
            th: 'Date Registered',
            render: (user, created_at) =>
              this.$moment(created_at).format('MMM Do YYYY')
          }
        ],
        filters: [
          { title: 'Bot Approved', name: 'bot-approved' },
          { title: 'Manual Approved', name: 'manual-approved' }
        ]
      })
    };
  },
  methods: {
    click(user) {
      this.users.selected = user;
      this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    authorizer(creditApplication) {
      creditApplication?.authorizer_id
        ? `${creditApplication?.authorizer?.name} ${creditApplication?.autorizer?.last_name}`
        : 'Loan Bot';
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    }
  }
};
</script>
